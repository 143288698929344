<template>

    <div class="loginPage">
        <el-form :model="LoginForm" :rules="LoginFormRule" ref="LoginForm" label-position="left" label-width="0px"
                 class="login-container">
            <div class="inner">
                <p><img src="../assets/logo.png"></p>
                <!-- <p><img src="../assets/biaoti.png" alt="" style="height:1rem;margin-bottom: 1.66rem"></p> -->
                <!-- <el-tabs v-model="activeName" @tab-click="handleClick">
                <el-tab-pane label="账号密码登录" name="account"> -->
                <el-form-item prop="username">
                    <el-input type="text" @keyup.enter.native="HandleSubmit" v-model="LoginForm.username"
                              auto-complete="off" placeholder="账号">
                        <template slot="prepend"><i class="el-icon-user"></i></template>
                    </el-input>
                </el-form-item>
                <el-form-item prop="password">
                    <el-input type="password" class="remember" @keyup.enter.native="HandleSubmit"
                              v-model="LoginForm.password" auto-complete="off" placeholder="密码" show-password>
                        <template slot="prepend"><i class="el-icon-edit-outline"></i></template>
                    </el-input>
                </el-form-item>
                <!-- <el-checkbox v-model="checked" checked >记住密码</el-checkbox> -->
                <el-form-item style="width:100%;">
                    <el-button type="primary" icon="el-icon-search"
                               style="width:100%;background:#598BFE;border:1px solid #598BFE;margin-top:1.11rem"
                               @click.native.prevent="HandleSubmit" :loading="logining">登 录
                    </el-button>
                </el-form-item>
                <!-- </el-tab-pane>
                
              </el-tabs> -->
                <!-- <div class="weixin" @click="handleClick">
                    <img src="../assets/home/weixin.png" alt="" class="loginimg">
                    <span>微信</span>
                </div> -->

            </div>
           
        </el-form>
        <div class="fixed">
            copyright2021-四川辰鳗科技有限公司-蜀ICP备2020027900号-2
        </div>
    </div>

</template>

<script>
export default {
    data() {
        return {
            logining: false,
                LoginForm: {
                    username: '',
                    password: ''
                },
                LoginFormRule: {
                    username: [
                        {required: true, message: '请输入账号', trigger: 'blur'},
                        //{ validator: validaePass }
                    ],
                    password: [
                        {required: true, message: '请输入密码', trigger: 'blur'},
                        //{ validator: validaePass2 }
                    ]
                },
                checked: true,
        }
    },
    methods: {
        HandleSubmit(){
            this.logining = true
            sessionStorage.setItem('islogin',true)
            setTimeout(() => {
                this.$router.push({name:"home"})
            }, 1000);
        },
        handleClick(){

        }
    },
}
</script>

<style lang="scss">
    .weixin {
        display: flex;
        justify-content: center;
        align-items: center;
        cursor: pointer;
        margin-top: 1rem;

        .loginimg {
            height: 1.11rem !important;
            margin: 0;
        }

        span {
            padding-left: 0.27rem;
          font-size: 0.8rem;
        }
    }

    .loginPage {
        position: relative;
        top: 0px;
        bottom: 0px;
        width: 100%;
        height: 100Vh;
        background-color: #4f4f4f88;
        background: url('../assets/banner.jpg') center;
        background-size: 100% 100%;
    }
    .fixed{
        position: absolute;
        bottom: 6px;
        letter-spacing: 2px;
        left: 35%;
        color: #fff;
    }
    a.pdl b.weixin-icon {
        background-position: -1.11rem 0;
    }


    .inner {
        width: 80%;
        margin: auto;
        text-align: center;
        img {
            height: 5rem;
            margin-top: 2rem;
            margin-bottom: 2rem;
        }

        p {
            height: 8.5rem;
        }
    }

    .login-container {
        // box-shadow: 0 0px 8px 0 rgba(0, 0, 0, 0.3), 0 1px 0px 0 rgba(0, 0, 0, 0.3);
        -webkit-border-radius: 5px;
        border-radius: 0.55rem;
        -moz-border-radius: 5px;
        background-clip: padding-box;
        // margin-bottom: 20px;
        padding: 20px;
        // background: url('../assets/btm.png');
        // background-color: #ffffff88;
        background-image: url('../assets/btm.png');
        background-size: 100% 100%;
        background-repeat: no-repeat;
        margin-top: 17.88rem;
        float: right;
        margin-right: 17.88rem;
        // border: 1px solid #8492A6;
        width: 30rem;
        height: 33rem;
        padding: 1.94rem 1.94rem 0.83rem 1.94rem;

        .title {
            margin: 1.11rem auto 2.22rem auto;
            text-align: center;
            // color: #505458;
            font-family: '';
            color: rgba(90, 140, 254, .9);
        }

        .remember {
            margin: 0.27rem 0;
        }

        // img{
        //   height: 50px;
        //   width: 300px;
        // }
    }
    .el-form-item {
      margin-bottom: 1.22rem;
    }
    ::v-deep .el-form-item__content {
      line-height: 2.22rem;
      position: relative;
      font-size: 0.77rem;
    }

    ::v-deep .el-input__inner {
      height: 2.22rem;
    }
    ::v-deep .el-button {
      height: 2.66rem;
      font-size: 0.77rem;
      padding: 0;
      justify-content: center;
      align-items: center;
    }
</style>