import Vue from 'vue'
import VueRouter from 'vue-router'
import HomeView from '../views/HomeView.vue'
import Login from '../views/Login'
import tools from '../views/tools'
Vue.use(VueRouter)

const routes = [
  {
    path: '/Login',
    component: Login,
    name: 'Login',
  },
  {
    path: '/',
    redirectr: "/Login",
    name: 'home',
    component: HomeView,
    children:[
      {
        path: '/operationalMonitoring',
        name: 'operationalMonitoring',
        component: () => import('../views/operationalMonitoring.vue')
      },
      {
        path: '/energySavingAnalysis',
        name: 'energySavingAnalysis',
        component: () => import('../views/energySavingAnalysis.vue')
      },
      {
        path: '/liftMian',
        name: 'liftMian',
        component: () => import('../views/liftMian.vue')
      }
    ]
  },
  {
    path: '/tools',
    component: tools,
    name: 'tools',
  },
]
// 在重写push|replace方法时，先把VueRouter原型对象的push|replace保存一份
let originPush = VueRouter.prototype.push
let originReplace = VueRouter.prototype.replace
// 参数1：告诉原来的push | replace方法，你往那里跳转（传递那些参数）
// 参数2：成功回调
// 参数3：失败回调
VueRouter.prototype.push = function (location, resolve, reject) {
    if (resolve && reject) {
        originPush.call(this, location, resolve, reject)
    } else {
        originPush.call(this, location, () => { }, () => { })
    }
}
VueRouter.prototype.replace = function (location, resolve, reject) {
    if (resolve && reject) {
        originReplace.call(this, location, resolve, reject)
    } else {
        originReplace.call(this, location, () => { }, () => { })
    }
}
const router = new VueRouter({
  mode: "history",
  base: window.__POWERED_BY_QIANKUN__?"/xm_dt":"",
  routes,
});
router.beforeEach((route, redirect, next) => {
  let qiankun = window.__POWERED_BY_QIANKUN__;
  if(!qiankun) {
    let islogin = sessionStorage.islogin
  if (!islogin && route.path !== '/login') {
      next({path: '/login'})
      return
  }
  }
  
  next()
});

export default router
