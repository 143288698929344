<template>
  <el-container class="first-page-container">
    <el-header class="pageHeader" v-if="!isQiankun">
      <div>
          <img
            src="../assets/home/logo.png"
          />
      </div>
      <div class="logoOutBtn">
        <img src="../assets/out.png" alt="" />
          <el-button
            type="text"
            style="color: #fff; font-size: 14px"
            @click.native="logout"
            >退 出</el-button
          >
      </div>
    </el-header>
    <el-container>
      <el-aside  v-if="!isQiankun">
        <!-- <el-radio-group v-model="isCollapse" style="margin-bottom: 20px">
          <el-radio-button :label="false">展开</el-radio-button>
          <el-radio-button :label="true">收起</el-radio-button>
        </el-radio-group> -->
        <el-menu
          :default-active="defaultActive"
          class="el-menu-vertical-demo"
          @open="handleOpen"
          @close="handleClose"
          :collapse="isCollapse"
          active-text-color="#fff"
          text-color="#6E7075"
          @select="goPath"
        >
          <el-submenu class="el-menu-demo" index="1">
            <template slot="title">
              <i class="el-icon-s-order"></i>
              <span slot="title">电梯</span>
            </template>
            <el-menu-item-group>
              <el-menu-item index="1-1">首页</el-menu-item>
              <el-menu-item index="1-3">运行监测</el-menu-item>
              <el-menu-item index="1-2">节能分析</el-menu-item>
            </el-menu-item-group>              
          </el-submenu>
        </el-menu>
      </el-aside>
      <el-main>
        <router-view></router-view>
      </el-main>
    </el-container>
  </el-container>
</template>

<script>
// @ is an alias to /src
import HelloWorld from "@/components/HelloWorld.vue";

export default {
  name: "HomeView",
  data() {
    return {
      isCollapse: false,
      defaultActive:'',
      isQiankun: false
    };
  },
  components: {
    HelloWorld,
  },
  methods: {
    handleOpen(key, keyPath) {
      console.log(key, keyPath);
    },
    handleClose(key, keyPath) {
      console.log(key, keyPath);
    },
    goPath(e){
      switch (e) {
        case '1-1':
          this.$router.push({name:'liftMian'})
          break;
        case '1-2':
          this.$router.push({name:'operationalMonitoring'})
          break;
        case '1-3':
          this.$router.push({name:'energySavingAnalysis'})
          break;
      }
    },
    logout(){
      sessionStorage.clear('islogin')
      this.$router.push({ name: "Login" });
    }
  },
  mounted(){
    this.isQiankun = window.__POWERED_BY_QIANKUN__
    let tree = {
      liftMian : '1-1',
      operationalMonitoring : '1-2',
      energySavingAnalysis : '1-3'
    }
    let currentPath = this.$route.name
    if(currentPath == 'home'){
      this.defaultActive = '1-1'
      this.goPath('1-1')
    }
    for (const key in tree) {
      if(currentPath === key){
        this.defaultActive = tree[key]
      }
    }
  },
};
</script>
<style lang="scss" scoped>
.el-header,
.el-footer {
  background-color: #527BD9;
  color: #333;
  height: 70px !important;
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.el-aside {
  color: #333;
  min-height: 100vh;
  width: 236px !important;
}

.el-main {
  background-color: #F9FBFF;
  color: #333;
  text-align: center;
}
.el-menu-demo {
  background: #fff;
}
.logoOutBtn{
  width: 60px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  font-family: Source Han Sans CN;
  font-weight: 400;
  color: #FFFFFF;
}
</style>
<style>
.first-page-container .el-menu-item{
  font-size: 14px;
  font-family: Source Han Sans CN;
  font-weight: 400;
  color: #6E7075;
}
.first-page-container .el-menu-item.is-active {
  background-color: #527BD9;
}
</style>