import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import ElementUI from 'element-ui';
import 'element-ui/lib/theme-chalk/index.css';
import * as echarts from 'echarts'
import "./utils/public-path.js";

Vue.prototype.$echarts = echarts;
Vue.config.productionTip = false
Vue.use(ElementUI)
let instance,route;
function render(props = {}) {
  route = router;
  const { container } = props;
  instance = new Vue({
    router: route,
    store,
    render: (h) => h(App),
  });
  instance.$mount(container ? container.querySelector("#app") : "#app");
}

if (!window.__POWERED_BY_QIANKUN__) {
  render();
}

export async function bootstrap() {}

export async function mount(props) {
  render(props);
}

export async function unmount() {
  instance.$destroy();
  instance = null;
  route = null;
}



